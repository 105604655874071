import { GET_MASTER_TEMPLATE_SUCCESS } from '../actions/template'

export interface ITemplateState {
  masterTemplates: any[]
}
export const getInitialState = () => ({
  masterTemplates: [],
})

const templateReducer = (state: ITemplateState = getInitialState(), action: any) => {
  switch (action.type) {
    case GET_MASTER_TEMPLATE_SUCCESS:
      return {
        ...state,
        masterTemplates: action.payload,
      }
    default:
      return state
  }
}

export default templateReducer
