import moment from 'moment'
import * as R from 'ramda'

export const stringAvatar = (name: string, avatarSx?: any) => {
  const sx = {
    width: 34,
    height: 34,
    fontSize: 'small',
    backgroundColor: '#5FA6DB',
    ...avatarSx,
  }
  if (name && name !== 'unknown') {
    const nameSplit = name.split(' ')

    return {
      sx,
      children: `${nameSplit[0][0]}${nameSplit.length > 1 ? nameSplit[1][0] : ''}`,
    }
  } else
    return {
      sx,
    }
}

export const escapeRegExp = (value: string): string => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export const calendarDefaults = {
  fixedWeekCount: false,
  // showNonCurrentDates: false,
  scrollTime: '08:00:00',
  dayMaxEvents: 6,
  nowIndicator: true,
  viewClassNames: 'full-calendar-view',
  initialView: 'timeGridWeek',
  moreLinkClassNames: 'custom-more-link',
  moreLinkContent: (arg) => arg.num + ' more',
  // displayEventTime: false,
}

export const formatDate = (date: Date) => {
  return moment(date).format(dateFormat)
}

export const formatTime = (date: Date) => {
  return moment(date).format('hh:mm:ss A')
}

export const constants = {
  emailRegex: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$',
  welcomeTitle: 'Welcome to KJT',
  welcomeSubtitle: 'Please enter your credentials to start using the app',
}

export const ROLES = {
  CLIENT_OBSERVER: 'Client Observer',
  FIELD_TEAM: 'Field Team',
  MODERATOR_EXTERNAL: 'Moderator  External',
  MODERATOR_INTERNAL: 'Moderator - Internal',
  PLATFORM_ADMIN: 'Platform Admin',
  PROGRAMMER: 'Programmer',
  RESEARCHER: 'Researcher',
  VENDOR: 'Vendor',
}

export const isUnauthorizedError = R.pathEq(['response', 'status'], 401)
export const isNetworkError = R.pathEq(['response', 'status'], 500)

export const allowOnlyNumericsOrDigits = /^[0-9\b]+$/
export const allowDecimalNumerics = /^[1-9]\d*(\.\d+)?$/

export const dateFormat = 'MMM DD, yyyy'
export const keyDateFormat = 'MM-DD-yyyy'
export const standardDateFormat = 'YYYY-MM-DD'

export const offset = (dateTime: string) => {
  const utcTime = new Date(dateTime)
  const tzoffset = new Date().getTimezoneOffset() * 60000
  const localTime = new Date(utcTime.getTime() - tzoffset)
  return localTime
}

export const getBooleanValue = (value: string | number) => (value ? true : false)

export const isValidUrl = (str: string) => {
  /* eslint-disable no-useless-escape */
  const regexp = new RegExp(
    /^(?:(?:\w+):\/\/)?(?:(?:(?:(?:\d{1,3}\.){3}\d{1,3})|(?:[a-z0-9\u00a1-\uffff]+(?:\.[a-z0-9\u00a1-\uffff]+)*))(?:\:\d{2,5})?)(?:\/[^\s]*)?$/
  )
  return regexp.test(str)
}
