import { createTheme } from '@mui/material'
import Icon from './components/icon'
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 360,
      sm: 640,
      md: 768,
      lg: 1368,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Tahoma',
  },
  palette: {
    primary: {
      main: '#27264C',
    },
    secondary: {
      main: '#FDB940',
    },
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: (props) => (
          <Icon
            {...props}
            color="#5fa6db"
            style={{ color: '#5fa6db', width: 16, height: 16, marginRight: 5 }}
            icon={props.className?.includes('MuiSelect-iconOpen') ? 'up' : 'Icon-down'}
          />
        ),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: (
          <Icon
            color="#5fa6db"
            style={{ width: 16, height: 16, marginRight: 0 }}
            icon={'Icon-down'}
          />
        ),
      },
    },
  },
})

export default theme
