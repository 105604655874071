import { lazy } from 'react'
import { PAGES, ROUTES } from './constants'

const Auth = lazy(() =>
  import(/* webpackPrefetch: true */ '../auth').then((module) => ({
    default: module.default,
  }))
)
const NoAuthLayout = lazy(() =>
  import(/* webpackPrefetch: true */ '../layouts/no-auth').then((module) => ({
    default: module.NoAuthLayout,
  }))
)
const AuthLayout = lazy(() =>
  import(/* webpackPrefetch: true */ '../layouts/auth').then((module) => ({
    default: module.AuthLayout,
  }))
)
const Dashboard = lazy(() =>
  import(/* webpackPrefetch: true */ '../pages/dashboard/dashboard').then((module) => ({
    default: module.default,
  }))
)
const Projects = lazy(() =>
  import(/* webpackPrefetch: true */ '../pages/projects').then((module) => ({
    default: module.default,
  }))
)
const MasterCalendar = lazy(() =>
  import(/* webpackPrefetch: true */ '../pages/masterCalendar/masterCalendar').then((module) => ({
    default: module.default,
  }))
)
const Templates = lazy(() =>
  import(/* webpackPrefetch: true */ '../pages/templates').then((module) => ({
    default: module.default,
  }))
)
const UserRoles = lazy(() =>
  import(/* webpackPrefetch: true */ '../pages/userRoles').then((module) => ({
    default: module.default,
  }))
)
const Organizations = lazy(() =>
  import(/* webpackPrefetch: true */ '../pages/organizations').then((module) => ({
    default: module.default,
  }))
)
// const Help = lazy(() =>
//   import(/* webpackPrefetch: true */ '../pages/help/help').then((module) => ({
//     default: module.default,
//   }))
// )
const MasterRecruitGrid = lazy(() =>
  import(/* webpackPrefetch: true */ '../pages/masterRecruitGrid').then((module) => ({
    default: module.default,
  }))
)
const QualifiedRespondents = lazy(() =>
  import(/* webpackPrefetch: true */ '../pages/qualifiedRespondents').then((module) => ({
    default: module.default,
  }))
)
const PAGE_NOT_FOUND = lazy(() =>
  import(/* webpackPrefetch: true */ '../layouts/pageNotFound').then((module) => ({
    default: module.default,
  }))
)
export const appRoutes = [
  [ROUTES.AUTH + '/*', NoAuthLayout, Auth],
  [ROUTES.START, AuthLayout, Projects, PAGES.HomeScreen],
  [ROUTES.DASHBOARD, AuthLayout, Dashboard, PAGES.Reports],
  [ROUTES.PROJECTS + '/*', AuthLayout, Projects, PAGES.HomeScreen],
  [ROUTES.CALENDAR, AuthLayout, MasterCalendar, PAGES.MasterCalendar],
  [ROUTES.TEMPLATES + '/*', AuthLayout, Templates, PAGES.TemplateLibrary],
  [ROUTES.USER_ROLES + '/*', AuthLayout, UserRoles, PAGES.UserRoles],
  [ROUTES.ORGANIZATIONS + '/*', AuthLayout, Organizations, PAGES.Organizations],
  // [ROUTES.HELP, AuthLayout, Help, PAGES.Help],
  [ROUTES.MASTER_RECRUIT_GRID, AuthLayout, MasterRecruitGrid, PAGES.MasterRecruitGrid],
  [ROUTES.QUALIFIED_RESPONDENTS + '/*', NoAuthLayout, QualifiedRespondents],
  ['*', NoAuthLayout, PAGE_NOT_FOUND],
]
