import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import PageNotAccessible from '../layouts/pageNotAccessible'
import { RootState } from '../redux/reducers'
import { PAGES } from './constants'
import { appRoutes } from './route-configs'

const renderRoutePage = (Layout: any, Component: any, props: any) => (
  <Layout>
    <Suspense fallback={true}>
      <Component {...props} />
    </Suspense>
  </Layout>
)

export const AppRoutes = () => {
  const {
    commonReducer: { isFetching },
    authReducer: { features },
  } = useSelector((state: RootState) => state)
  return (
    <>
      <Routes>
        {appRoutes.map(([routeName, Layout, Component, Page]) => {
          // eslint-disable-next-line
          const view =
            Page === undefined ||
            (features &&
              (features as any)[Page as keyof typeof PAGES] &&
              (features as any)[Page as keyof typeof PAGES].CanRead)
          return (
            <Route
              key={routeName as string}
              path={routeName as string}
              element={
                view
                  ? renderRoutePage(Layout, Component, null)
                  : renderRoutePage(Layout, !isFetching ? PageNotAccessible : () => <></>, null)
              }
            />
          )
        })}
      </Routes>
    </>
  )
}
