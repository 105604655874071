export const SELECTED_PROJECT = 'SELECTED_PROJECT'
export const PROJECT_TYPES_SUCCESS = 'PROJECT_TYPES_SUCCESS'
export const INTERVIEW_METHODS_SUCCESS = 'INTERVIEW_METHODS_SUCCESS'
export const PROJECT_STATUSES_SUCCESS = 'PROJECT_STATUSES_SUCCESS'
export const PROJECT_SUCCESS = 'PROJECT_SUCCESS'
export const PROJECT_USERS_SUCCESS = 'PROJECT_USERS_SUCCESS'
export const RESPONDENT_STATUSES_SUCCESS = 'RESPONDENT_STATUSES_SUCCESS'
export const SELECTED_TIMEZONE = 'SELECTED_TIMEZONE'
export const SELECTED_PROJECT_USERS = 'SELECTED_PROJECT_USERS'
export const SELECTED_PROJECT_RESPONDENTS = 'SELECTED_PROJECT_RESPONDENTS'
export const PROJECT_ADDITIONAL_DETAIL_SUCCESS = 'PROJECT_ADDITIONAL_DETAIL_SUCCESS'

export const dispatchSelectedProject = (payload: any) => ({
  type: SELECTED_PROJECT,
  payload,
})
export const dispatchProjectTypes = (payload: any) => ({
  type: PROJECT_TYPES_SUCCESS,
  payload,
})
export const dispatchInterviewMethods = (payload: any) => ({
  type: INTERVIEW_METHODS_SUCCESS,
  payload,
})
export const dispatchProjectStatuses = (payload: any) => ({
  type: PROJECT_STATUSES_SUCCESS,
  payload,
})
export const dispatchFethProjectsSuccess = (payload: any) => ({
  type: PROJECT_SUCCESS,
  payload,
})
export const dispatchFethProjectAdditionalDetail = (payload: any) => ({
  type: PROJECT_ADDITIONAL_DETAIL_SUCCESS,
  payload,
})
export const dispatchProjectUsers = (payload: any) => ({
  type: PROJECT_USERS_SUCCESS,
  payload,
})

export const dispatchRespondentStatuses = (payload: any) => ({
  type: RESPONDENT_STATUSES_SUCCESS,
  payload,
})
// Project Calendar
export const dispatchSelectedTimezone = (payload: any) => ({
  type: SELECTED_TIMEZONE,
  payload,
})
export const dispatchSelectedProjectUsers = (payload: any) => ({
  type: SELECTED_PROJECT_USERS,
  payload,
})
export const dispatchSelectedProjectRespondents = (payload: any) => ({
  type: SELECTED_PROJECT_RESPONDENTS,
  payload,
})
