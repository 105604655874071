import { combineReducers } from 'redux'
import modalReducer from '../../modals/modal-reducer'
import authReducer from './auth'
import commonReducer from './common'
import projectReducer from './projects'
import templateReducer from './template'
import userReducer from './user'

const rootReducer = combineReducers({
  authReducer,
  modalReducer,
  projectReducer,
  userReducer,
  commonReducer,
  templateReducer,
})
export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
