import {
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_TYPES_SUCCESS,
  GET_PII_ACCESS_SUCCESS,
  GET_TIMEZONES_SUCCESS,
  SET_ISFETCHING,
} from '../actions/common'

export interface ICommonState {
  isFetching: boolean
  organizations: any[]
  organizationTypes: any[]
  timezones: any[]
  piiAccessTypes: any[]
}
export const getInitialState = () => ({
  isFetching: false,
  organizations: [],
  organizationTypes: [],
  timezones: [],
  piiAccessTypes: [],
})

const commonReducer = (state: ICommonState = getInitialState(), action: any) => {
  switch (action.type) {
    case SET_ISFETCHING:
      return {
        ...state,
        isFetching: action.payload,
      }
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
      }
    case GET_ORGANIZATION_TYPES_SUCCESS:
      return {
        ...state,
        organizationTypes: action.payload,
      }
    case GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: action.payload,
      }
    case GET_PII_ACCESS_SUCCESS: {
      return {
        ...state,
        piiAccessTypes: action.payload,
      }
    }
    default:
      return state
  }
}

export default commonReducer
