import axios from 'axios'
import * as R from 'ramda'
import { handleHttpResponse } from '../redux/utils'

export const apiServer = process.env.REACT_APP_API_URL || 'http://localhost:3000'
export const apiBaseUrl = `${apiServer}`

export const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
})

export const basicAuthRequest = async (config: any) => {
  let token = localStorage.getItem('token')
  if (config.token) {
    token = config.token
  }
  const withAuthTokenConfig = R.mergeDeepLeft(config, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return withAuthTokenConfig
}
/**
 * authRequest :: AxiosConfig -> Promise Error AxiosResponse
 * AxiosConfig = Object
 * AxiosResponse = Object
 */
export const authRequest = async (config: any) => {
  try {
    const withAuthTokenConfig = await basicAuthRequest(config)
    const data = await axiosInstance(withAuthTokenConfig)
    handleHttpResponse()
    return data
  } catch (error) {
    handleHttpResponse(error)
    return await Promise.reject(error)
  }
}

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const encodeQueryData = (data: any) => {
  const ret: any = []
  Object.entries(data).forEach((d: any) => {
    if (d[1]) {
      ret.push(encodeURIComponent(d[0]) + '=' + encodeURIComponent(d[1]))
    }
  })
  return ret.join('&')
}
