export const ROUTES = {
  START: '/',
  AUTH: '/auth',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgotPassword',
  RESET_PASSWORD: '/resetPassword',
  DASHBOARD: '/dashboard',
  PROJECTS: '/projects',
  CALENDAR: '/calendar',
  TEMPLATES: '/templates',
  USER_ROLES: '/users',
  ORGANIZATIONS: '/organizations',
  HELP: '/help',
  QUALIFIED_RESPONDENTS: '/qr',
  MASTER_RECRUIT_GRID: '/recruitGrid',
}

export const PAGES = {
  AboutProject: 'AboutProject',
  Help: 'Help',
  HomeScreen: 'HomeScreen',
  ManageAccess: 'ManageAccess',
  MasterCalendar: 'MasterCalendar',
  MeetingScreen: 'MeetingScreen',
  ProjectCalendar: 'ProjectCalendar',
  ProjectDetail: 'ProjectDetail',
  RecruitGrid: 'RecruitGrid',
  Reports: 'Reports',
  TemplateLibrary: 'TemplateLibrary',
  UserRoles: 'UserRoles',
  Organizations: 'Organizations',
  Screening: 'Screening',
  Send_Email: 'SendEmail',
  ProjectTemplate: 'ProjectTemplate',
  MasterRecruitGrid: 'MasterRecruitGrid',
}
