import { parseJwt } from '../../api/utils'
import { GET_FEATURES_SUCCESS, LOGIN_SUCCESS, LOGOUT } from '../actions/auth'

export interface IAuthState {
  isAuthorized: boolean
  userDetail: object
  token: string
  features: object
  logout: boolean
}
export const getInitialState = () => ({
  isAuthorized: false,
  userDetail: {},
  token: '',
  features: {},
  logout: true,
})

const authReducer = (state: IAuthState = getInitialState(), action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      const userDetail = parseJwt(action.payload.token)
      return {
        ...state,
        logout: false,
        isAuthorized: true,
        token: action.payload.token,
        userDetail: { ...action.payload, ...userDetail },
      }
    case GET_FEATURES_SUCCESS:
      return {
        ...state,
        features: action.payload[0],
      }
    case LOGOUT:
      localStorage.removeItem('token')
      return {
        ...state,
        logout: true,
        isAuthorized: false,
        token: '',
      }
    default:
      return state
  }
}

export default authReducer
