import { IProject } from '../../models/projects'
import { IUser } from '../../models/user'
import {
  INTERVIEW_METHODS_SUCCESS,
  PROJECT_STATUSES_SUCCESS,
  PROJECT_SUCCESS,
  PROJECT_TYPES_SUCCESS,
  PROJECT_USERS_SUCCESS,
  SELECTED_PROJECT,
  RESPONDENT_STATUSES_SUCCESS,
  SELECTED_TIMEZONE,
  SELECTED_PROJECT_USERS,
  SELECTED_PROJECT_RESPONDENTS,
  PROJECT_ADDITIONAL_DETAIL_SUCCESS,
} from '../actions/projects'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_CURRENT_MODAL = 'CLOSE_CURRENT_MODAL'
export const defaultState = { name: '', payload: {} }

export interface IProjectState {
  projects: any[]
  projectDetail: IProject
  projectTypes: any[]
  interviewMethods: any[]
  projectStatuses: any[]
  projectUsers: any[]
  respondentStatuses: any[]
  selectedTimezone: string
  selectedProjectUsers: any[]
  selectedProjectRespondents: any[]
  selectedProjectCalendarSlots: any[]
}

export const getInitialState = () => ({
  projects: [],
  projectDetail: {} as any as IProject,
  projectTypes: [],
  interviewMethods: [],
  projectStatuses: [],
  projectUsers: [],
  respondentStatuses: [],
  selectedTimezone: '',
  selectedProjectUsers: [],
  selectedProjectRespondents: [],
  selectedProjectCalendarSlots: [],
})

const projectReducer = (state: IProjectState = getInitialState(), action: any) => {
  switch (action.type) {
    case SELECTED_PROJECT:
      return {
        ...state,
        projectDetail: action.payload,
      }
    case PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        projectTypes: action.payload,
      }
    case INTERVIEW_METHODS_SUCCESS:
      return {
        ...state,
        interviewMethods: action.payload,
      }
    case PROJECT_STATUSES_SUCCESS:
      return {
        ...state,
        projectStatuses: action.payload,
      }
    case PROJECT_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }
    case PROJECT_ADDITIONAL_DETAIL_SUCCESS:
      const { id } = action.payload
      const updatedProjects = [...state.projects]
      const idx = state.projects.findIndex((x) => x.id === id)
      updatedProjects[idx] = { ...updatedProjects, ...action.payload }
      return {
        ...state,
        projects: updatedProjects,
      }
    case PROJECT_USERS_SUCCESS:
      return {
        ...state,
        projectUsers: action.payload
          .filter((user: IUser) => user.isActive)
          .map((user: IUser) => ({ id: user.id, name: user.firstName + ' ' + user.lastName })),
      }
    case RESPONDENT_STATUSES_SUCCESS:
      return {
        ...state,
        respondentStatuses: action.payload,
      }
    case SELECTED_TIMEZONE:
      return {
        ...state,
        selectedTimezone: action.payload,
      }
    case SELECTED_PROJECT_USERS:
      return {
        ...state,
        selectedProjectUsers: action.payload
          .filter((user: any) => user.isActive && user.isModerator)
          .map((user: any) => ({
            ...user,
            fullName: user.firstName + ' ' + user.lastName,
          })),
      }
    case SELECTED_PROJECT_RESPONDENTS:
      return {
        ...state,
        selectedProjectRespondents: action.payload.map((user: any) => ({
          ...user,
          fullName: user.firstName + ' ' + user.lastName,
        })),
      }
    default:
      return state
  }
}

export default projectReducer
