import { GET_ROLES_SUCCESS, GET_USERS_SUCCESS } from '../actions/user'

export interface IUserState {
  roles: any[]
  users: any[]
}
export const getInitialState = () => ({
  roles: [],
  users: [],
})

const userReducer = (state: IUserState = getInitialState(), action: any) => {
  switch (action.type) {
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }
    default:
      return state
  }
}

export default userReducer
