export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_TYPES_SUCCESS = 'GET_ORGANIZATION_TYPES_SUCCESS'
export const GET_TIMEZONES_SUCCESS = 'GET_TIMEZONES_SUCCESS'
export const GET_PII_ACCESS_SUCCESS = 'GET_PII_ACCESS_SUCCESS'
export const SET_ISFETCHING = 'SET_ISFETCHING'

export const startFetching = () => ({
  type: SET_ISFETCHING,
  payload: true,
})
export const stopFetching = () => ({
  type: SET_ISFETCHING,
  payload: false,
})

export const dispatcGetOrganizationSuccess = (payload: any) => ({
  type: GET_ORGANIZATION_SUCCESS,
  payload,
})
export const dispatcGetOrganizationTypesSuccess = (payload: any) => ({
  type: GET_ORGANIZATION_TYPES_SUCCESS,
  payload,
})
export const dispatcGetTimezonesSuccess = (payload: any) => ({
  type: GET_TIMEZONES_SUCCESS,
  payload,
})
export const dispatcGetPIIAccessTypesSuccess = (payload: any) => ({
  type: GET_PII_ACCESS_SUCCESS,
  payload,
})
