import React, { Fragment, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppRoutes } from './routes/routes'
import './App.scss'
import { Spinner } from './components/spinner'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import theme from './theme'
import { ToastContainer } from 'react-toastify'

const App = () => {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<Spinner />}>
            <Fragment>
              <main>
                <AppRoutes />
                <ToastContainer />
              </main>
            </Fragment>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  )
}

export default App
