export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const LOGOUT = 'LOGOUT'

export const dispatcGetRoleSuccess = (payload: any) => ({
  type: GET_ROLES_SUCCESS,
  payload,
})

export const dispatcGetUsersSuccess = (payload: any) => ({
  type: GET_USERS_SUCCESS,
  payload,
})
