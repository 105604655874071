export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const GET_FEATURES_SUCCESS = 'GET_FEATURES_SUCCESS'

export const dispatchLoginSuccess = (payload: any) => ({
  type: LOGIN_SUCCESS,
  payload,
})

export const dispatchLogout = () => ({
  type: LOGOUT,
})

export const dispatchGetFeaturesSuccess = (payload: any) => ({
  type: GET_FEATURES_SUCCESS,
  payload,
})
