import { toast } from 'react-toastify'
import { isNetworkError, isUnauthorizedError } from '../utils'
import { dispatchLogout } from './actions/auth'
import { stopFetching } from './actions/common'
import { store } from './store'

export const handleHttpResponse = (error: any = null) => {
  if (isUnauthorizedError(error)) {
    store.dispatch(stopFetching())
    store.dispatch(dispatchLogout())
  }
  if (isNetworkError(error)) {
    store.dispatch(stopFetching())
    toast.error('Network Error', { toastId: 'networkError' })
  }
  return error
}
